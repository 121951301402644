// @ts-strict-ignore
import { Box, Flex, Grid, OldHeading, OldText } from '@gassan-ui'
import { getAmsterdamTimezone } from '@lib/getAmsterdamTimezone'
import { RegularHours, SpecialHours } from '@pages/_stores/[...slug]'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type Props = {
  isNl: boolean
  regularHours: RegularHours
  specialHours: SpecialHours
  openingHoursOverwrite?: string
}

const daysToNl = {
  monday: 'Maandag',
  tuesday: 'Dinsdag',
  wednesday: 'Woensdag',
  thursday: 'Donderdag',
  friday: 'Vrijdag',
  saturday: 'Zaterdag',
  sunday: 'Zondag',
}

const OpeningHours: FC<Props> = ({ isNl, regularHours, specialHours, openingHoursOverwrite }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('other')
  const currentTimeZone = getAmsterdamTimezone()

  return (
    <Box maxWidth="37rem">
      <Box>
        <OldHeading variant="h2" as="h2">
          {t('openingHours')}
        </OldHeading>
        {openingHoursOverwrite ? (
          <OldText as="span" color="shades.600">
            {openingHoursOverwrite}
          </OldText>
        ) : (
          <Grid
            gridTemplateColumns={{ _: '1fr', small: '1fr 1fr' }}
            gridColumnGap={{ _: '2rem', xlarge: '4rem' }}
          >
            <Box mb={{ _: '2rem', small: '0' }}>
              <OldText variant="regularBold">{t('regularOpeningHours')}</OldText>
              {Object.keys(daysToNl).map((day, index) => (
                <Flex justifyContent="space-between" alignItems="center" key={index}>
                  <OldText as="span" color="shades.600">
                    {language === 'nl' ? daysToNl[day] : day.charAt(0).toUpperCase() + day.slice(1)}
                  </OldText>
                  <OldText as="span" color="shades.600">
                    {regularHours[day]}
                  </OldText>
                </Flex>
              ))}
              <OldText variant="small" color="shades.500" mb="0">
                {isNl
                  ? currentTimeZone === 'CET'
                    ? `Central European Time (${currentTimeZone})`
                    : `Central European Summer Time (${currentTimeZone})`
                  : 'Singapore Time (SGT)'}
              </OldText>
            </Box>
            <Box>
              <OldText variant="regularBold">{t('specialOpeningHours')}</OldText>
              {Object.keys(specialHours).map(
                (day, index) =>
                  specialHours[day].title &&
                  specialHours[day].value && (
                    <Flex justifyContent="space-between" alignItems="center" key={index}>
                      <OldText as="span" color="shades.600">
                        {specialHours[day].title}
                      </OldText>
                      <OldText as="span" color="shades.600">
                        {specialHours[day].value}
                      </OldText>
                    </Flex>
                  ),
              )}
            </Box>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default OpeningHours

import CollapsibleText from '@components/CollapsibleText'
import { Box, OldHeading, theme } from '@gassan-ui'
import { Service } from '@pages/_stores/[...slug]'
import { FC, useState } from 'react'

type Props = {
  items: Service[]
}

const Services: FC<Props> = ({ items }) => {
  const [active, setActive] = useState<string | null>(null)

  const toggleService = (codename: string) => {
    setActive(active === codename ? null : codename)
  }

  return (
    <Box>
      <OldHeading variant="h2" as="h2">
        Services
      </OldHeading>
      <Box borderTop={`1px solid ${theme.colors.shades[300]}`}>
        {items.map((service, index) => (
          <CollapsibleText
            title={service.title}
            description={
              service.descriptionNew !== '<p><br></p>' // This is the default response from Kontent..
                ? service.descriptionNew
                : service.description
            }
            key={index}
            isActive={service.codename === active}
            onSelect={() => toggleService(service.codename)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default Services
